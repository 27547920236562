<mat-card class="flex flex-col items-center p-4 w-full">
  <mat-progress-spinner
    #spinner
    [color]="white() ? 'accent' : 'primary'"
    mode="indeterminate"
    diameter="20"
  >
  </mat-progress-spinner>
  @if (text()) {

  <span>{{ text() }}</span>
  }
</mat-card>
