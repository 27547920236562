import { Component, effect, OnInit } from '@angular/core';
import { MaterialModule } from '../shared/ui/material/material.module';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { UserService } from '../shared/data-access/user.service';
import { LoaderComponent } from '../shared/ui/loader/loader.component';
import {
  Feature,
  features,
  Package,
  packages,
  PackageType,
  Plan,
  plans,
  PlanType,
} from '../erp-dashboard/schemas/erp-schema';
import { HttpClient } from '@angular/common/http';
import { RequestService } from '../shared/data-access/request-service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    imports: [CommonModule, RouterModule, MaterialModule, LoaderComponent]
})
export class DashboardComponent {
  firstName: string | undefined;
  lastName: string | undefined;
  loaded_subscription = false;
  has_subscription = false;
  is_in_trial = false;

  site_url: string = '';
  site_status: string = '';

  private subscriptionEndpoint = environment.BASE_URL + 'stripe/subscription';
  requestService: RequestService<any>;
  currentPackage!: PackageType;
  currentPlanType!: PlanType;
  features: Feature[] = features;
  packages: Package[] = packages;
  plans: Plan[] = plans;

  PlanType = PlanType;
  PackageType = PackageType;

  constructor(private userService: UserService, private http: HttpClient) {
    this.requestService = new RequestService<any>(this.http);
    effect(() => {
      this.setUserData();
    });
  }

  setUserData() {
    if (this.userService.userDataAvailable()) {
      this.firstName = this.userService.user().firstName;
      this.lastName = this.userService.user().lastName;
      this.getSubscription();
    }
  }

  filteredPackage(): Package[] {
    return this.packages.filter((pack) => pack.name === this.currentPackage);
  }
  filteredFeatures(): Feature[] {
    return this.features.filter((feature) => {
      if (feature.is_featured) return false;
      switch (this.currentPackage) {
        case PackageType.STARTER:
          // Only return features that belong to the BASE package
          return feature.package === PackageType.STARTER;

        case PackageType.GROWTH:
          // Return features from BASE and SERVICES packages
          return (
            feature.package === PackageType.STARTER ||
            feature.package === PackageType.GROWTH
          );

        case PackageType.SCALE:
          // Return features from BASE, SERVICES, and TRADING packages
          return (
            feature.package === PackageType.STARTER ||
            feature.package === PackageType.GROWTH ||
            feature.package === PackageType.SCALE
          );

        case PackageType.CORPORATE:
          // Return features from all packages
          return true;

        default:
          return false;
      }
    });
  }

  get filteredPlans(): Plan[] {
    return this.plans.filter((plan) => plan.package === this.currentPackage);
  }

  get filteredCurrentPlan(): Plan[] {
    return this.plans.filter(
      (plan) =>
        plan.package === this.currentPackage &&
        plan.type === this.currentPlanType
    );
  }

  monthlyPrice(plan: Plan): number {
    const discount = plan.discount ?? 0;
    const discountedPercentage = 1 - discount / 100;
    return plan.price * discountedPercentage;
  }

  async getSubscription() {
    try {
      this.requestService
        .simpleGetRequest(this.subscriptionEndpoint)
        .subscribe((res) => {
          const response = res as any;
          if (response.code === 200) {
            if (response.data === false) {
              this.currentPackage = PackageType.GROWTH;
              this.loaded_subscription = true;
              return;
            }

            this.currentPackage = response.data.product_code;
            this.currentPlanType = response.data.plan_code;
            this.is_in_trial = response.data.is_in_trial;

            this.site_status = response.data.site_status;
            this.site_url = response.data.site_url;

            if (this.site_status !== 'enabled' && !this.site_url) {
              this.checkSubscriptionSite();
            }

            // console.log(this.filteredPlans);
            this.loaded_subscription = true;
            this.has_subscription = true;
          } else {
            console.log('Failed!', response);
            this.loaded_subscription = true;
          }
        });
    } catch (error) {
      console.error(error);
      this.loaded_subscription = true;
    }
  }

  checkSubscriptionSite() {
    setTimeout(() => {
      if (this.site_status !== 'enabled' && !this.site_url) {
        this.getSubscription();
      }
    }, 10000);
  }
}
