<div class="w-full bg-white flex flex-row items-center justify-between text-sm">
    <div class="flex flex-row items-center justify-between md:mx-40 ml-2 mr-2 mx-4 w-full">
        <a routerLink="/welcome">
            <div>
                <img src="/assets/exseede_logo.png" class="h-16 mx-0" alt="Logo">
            </div>
        </a>
        <div class="hidden md:flex flex-row gap-4 items-center">
            <!-- <button [routerLinkActive]="'bg-[#062A30] text-white hover:!bg-[#062a30b7]'"
                class="px-4 py-2 hover:bg-[#062a30b7] hover:text-white rounded-full button-rounded"
                [routerLink]="['/erp']">
                <span class="whitespace-nowrap">ERP Next</span>
            </button> -->

            <button [routerLinkActive]="'bg-[#062A30] text-white hover:!bg-[#062a30b7]'"
                class="px-4 py-2 hover:bg-[#062a30b7] hover:text-white rounded-full button-rounded"
                routerLink="/financial-wizard">
                <span class="whitespace-nowrap">Financial Insights</span>
            </button>
            <button [routerLinkActive]="'bg-[#062A30] text-white hover:!bg-[#062a30b7]'"
                class="px-4 py-2 hover:bg-[#062a30b7] hover:text-white rounded-full button-rounded"
                routerLink="/academy">
                <span>Academy</span>
            </button>
            <button [routerLinkActive]="'bg-[#062A30] text-white hover:!bg-[#062a30b7]'"
                class="px-4 py-2 hover:bg-[#062a30b7] hover:text-white rounded-full button-rounded"
                routerLink="/funding">
                <span class="whitespace-nowrap">Funding Database</span>
            </button>

            <!-- <button mat-button *ngIf="no_subscription && !loading"
                class="button-rounded border !border-solid !border-[#062A30] !px-3 hover:!bg-[#062A30] hover:!text-white "
                routerLink="/erp">
                <span class="whitespace-nowrap">ERP Plans</span>
            </button>

            <a *ngIf="!no_subscription && !loading" mat-button
                class="button-rounded border !border-solid !border-[#062A30] !px-3 hover:!bg-[#062A30] hover:!text-white"
                target="_blank" [href]="site_url">
                <span class="whitespace-nowrap">ERP Dashboard</span>
            </a> -->
            <!-- <button [routerLinkActive]="'bg-[#062A30] text-white hover:!bg-[#062a30b7]'"
                class="px-4 py-2 hover:bg-[#062a30b7] hover:text-white rounded-full button-rounded"
                routerLink="/company">
                <span class="whitespace-nowrap">Company Profile</span>
            </button> -->

            <div class="flex items-center">
                <button mat-button class="button-rounded w-0 m-0" [matMenuTriggerFor]="menu">
                    <mat-icon class="!m-0" aria-hidden="false" aria-label="Notifications icon"
                        fontIcon="account_circle"></mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="onUserSelection('My Profile')" routerLink="/account/profile">My
                        Profile</button>
                    <button mat-menu-item class="logoutParent">
                        <a [href]="loc_origin + '/reset_password'">Change Password</a>
                    </button>
                    <button mat-menu-item (click)="onUserSelection('Help & Support')" routerLink="/account/support">Help
                        & Support</button>
                    <button mat-menu-item class="logoutParent justify-center" (click)="logout()">
                        <a>Logout</a>
                    </button>
                </mat-menu>
            </div>
        </div>

        <button class="md:hidden mr-4" id="menu-toggle" (click)="toggleMobileMenu()">
            <mat-icon aria-hidden="false" aria-label="Menu icon" fontIcon="menu"></mat-icon>
        </button>
    </div>

    <div *ngIf="mobileNavToggle"
        class="flex md:hidden absolute top-16 z-50  w-full bg-white flex-col items-center gap-2 shadow-lg pb-4">
        <button mat-button class="button-rounded" routerLink="/courses">
            <span>Academy</span>
        </button>
        <button mat-button class="button-rounded" routerLink="/funding">
            <span class="whitespace-nowrap">Funding Database</span>
        </button>
        <!-- <button mat-button class="button-rounded" routerLink="/company">
            <span class="whitespace-nowrap">Company Profile</span>
        </button> -->
        <button mat-button class="button-rounded" routerLink="/financial-wizard">
            <span class="whitespace-nowrap">Financial Insights</span>
        </button>

        <!-- <button *ngIf="no_subscription && !loading" mat-button
            class="button-rounded border !border-solid !border-[#062A30] !px-3 hover:!bg-[#062A30] hover:!text-white"
            routerLink="/erp">
            <span class="whitespace-nowrap">ERP Plans</span>
        </button>

        <a *ngIf="!no_subscription && !loading" mat-button
            class="button-rounded border !border-solid !border-[#062A30] !px-3 hover:!bg-[#062A30] hover:!text-white"
            [href]="site_url" target="_blank">
            <span class="whitespace-nowrap">ERP Dashboard</span>
        </a> -->

        <button mat-button class="button-rounded" routerLink="/financial-wizard">
            <span class="whitespace-nowrap">Financial Insights</span>
        </button>
        <!-- <button mat-button class="button-rounded" routerLink="/erp">
            <span class="whitespace-nowrap">ERP Next</span>
        </button> -->
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title class="text-center">
                    <mat-icon aria-hidden="false" aria-label="Notifications icon" fontIcon="account_circle"></mat-icon>
                    &nbsp; My Account
                </mat-panel-title>
            </mat-expansion-panel-header>
            <button class="!justify-center" mat-menu-item (click)="onUserSelection('My Profile')"
                routerLink="/account/profile">My Profile</button>
            <button class="!justify-center" mat-menu-item class="logoutParent">
                <a [href]="loc_origin + '/reset_password'">Change Password</a>
            </button>
            <button class="!justify-center" mat-menu-item (click)="onUserSelection('Help & Support')"
                routerLink="/account/support">Help & Support</button>
            <button class="!justify-center" mat-menu-item (click)="logout()"> Logout </button>
        </mat-expansion-panel>
    </div>

</div>