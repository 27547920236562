import { Injectable, signal } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AuthService } from '@auth0/auth0-angular';
import { RequestService } from './request-service';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom, map, Subject } from 'rxjs';
import { User } from '../models/User';
import { CompanyService } from 'src/app/company/data-access/company.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private userEndpointUri = environment.BASE_URL + 'user/';
  public user = signal<User>(new User());
  public userDataAvailable = signal<boolean>(false);
  public requestService: RequestService<User>;

  private userSubject = new Subject<boolean>();

  public userIsInitialized = this.userSubject.asObservable();

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private companyService: CompanyService,
    private router: Router
  ) {
    this.requestService = new RequestService<User>(this.http);
  }

  async initializeUserData() {
    try {
      // Getting AuthOAuthState
      if (this.authService.user$) {
        this.authService.user$
          .pipe(
            map((auth0UserData) => {
              // console.log('auth0UserData:', auth0UserData);

              // Loading User Data
              firstValueFrom(
                this.requestService.loadObject<User>(
                  this.userEndpointUri,
                  this.user()
                )
              )
                .then((data) => {
                  this.user.set(data);
                  console.log('this.user userData:', this.user());

                  if (this.user().emailAddress) this.userSubject.next(true);

                  if (!this.user().emailAddress) {
                    this.user.update((user) => {
                      user.firstName = auth0UserData?.given_name;
                      user.lastName = auth0UserData?.family_name;
                      user.emailAddress = auth0UserData?.email;
                      user.phoneNumber = auth0UserData?.phone_number;

                      return user;
                    });

                    this.saveUserData();
                  }

                  this.userDataAvailable.set(true);

                  return data;
                })
                .then(() => {
                  if (this.userDataAvailable() && this.user().emailAddress) {
                    if (!this.user().acceptedconditions) {
                      this.router.navigate(['/terms-conditions']);
                    }
                  }
                });
            })
          )
          .subscribe(() => {
            // location.reload();
          });
      }
    } catch (error) {
      this.userSubject.next(false);
      console.error('Error fetching data:', error);
    }
  }

  saveUserData() {
    // console.log('POSTING USER DATA:', this.user)
    return this.requestService.postRequest(this.userEndpointUri, this.user());
  }
}
