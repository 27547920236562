import { CommonModule } from '@angular/common';
import { Component, input, Input } from '@angular/core';
import { MaterialModule } from '../material/material.module';

@Component({
  imports: [CommonModule, MaterialModule],
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent {
  white = input(false);
  brand = input(false);
  text = input('');

  // constructor() { }
}
