import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { UserService } from './shared/data-access/user.service';

@Component({
    selector: 'app-root',
    imports: [RouterOutlet],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'sme-portal';

  constructor(
    private userService: UserService
  ) {
    this.userService.initializeUserData();
  }
}
