import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import {
  Observable,
  Subject,
  catchError,
  map,
  retry,
  takeUntil,
  throwError,
} from 'rxjs';
import { Serializable } from '../models/Serializable';
import { DataTemplate, RequestTemplate } from '../schemas/request-template';
import { Injectable, OnDestroy } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RequestService<T> implements OnDestroy {
  private _destroy$ = new Subject<void>();
  httpOptions = {
    headers: new HttpHeaders({}),
  };

  constructor(private http: HttpClient) {}

  loadObject<T extends Serializable>(
    endpointUri: string,
    serializable: T
  ): Observable<T> {
    return this.getRequest(endpointUri).pipe(
      map((req_data) => {
        let data = {} as DataTemplate;
        let data_is_not_array = false;
        const dataArray = [];

        // console.log("Loaded Object");
        if (endpointUri.includes('progress')) {
          const data_in = req_data.data as Array<any>;

          if (!data_in.length) {
            dataArray.push(req_data.data);
            data_is_not_array = true;
          }
        }

        if (req_data.data) {
          data = req_data.data as DataTemplate;
        } else {
          data = req_data as DataTemplate;
        }

        if (data_is_not_array) data = dataArray as DataTemplate;
        console.log('Loaded Object', data);

        serializable.deserialize(endpointUri, data);
        // console.log(serializable);
        return serializable;
      }),
      takeUntil(this._destroy$)
    );
  }

  loadArray<T, TObject extends Serializable>(
    endpointUri: string,
    serializable: { new (): TObject }
  ): Observable<TObject[]> {
    return this.getRequest(endpointUri).pipe(
      map((req_data) => {
        let data = req_data.data as DataTemplate[];
        const objects: TObject[] = [];

        if (data === undefined) {
          data = req_data as unknown as DataTemplate[];
          data.forEach((item) => {
            const TObjectInstance = new serializable();
            TObjectInstance.deserialize(endpointUri, item);
            objects.push(TObjectInstance);
          });
        } else {
          // console.log("Loaded Array", data);
          data.forEach((item) => {
            const TObjectInstance = new serializable();
            TObjectInstance.deserialize(endpointUri, item);
            objects.push(TObjectInstance);
          });
        }

        // console.log("Loaded Array", objects);
        return objects;
      }),
      takeUntil(this._destroy$)
    );
  }

  getRequest(endpointUri: string) {
    return this.http.get<RequestTemplate<T>>(endpointUri);
  }

  postRequest(endpointUri: string, serializable: Serializable) {
    console.log('POST REQUEST', endpointUri, serializable);
    return this.http
      .post(endpointUri, serializable.serialize(endpointUri), this.httpOptions)
      .pipe(takeUntil(this._destroy$))
      .subscribe();
  }

  simplePostRequest(endpointUri: string, data: any) {
    console.log('SIMPLE POST REQUEST', endpointUri, data);
    return this.http
      .post(endpointUri, data, this.httpOptions)
      .pipe(takeUntil(this._destroy$));
  }
  simpleGetRequest(endpointUri: string) {
    console.log('SIMPLE GET REQUEST', endpointUri);
    return this.http.get(endpointUri).pipe(takeUntil(this._destroy$));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      console.error('An error occurred:', error.error);
    } else {
      console.error(
        `Backend returned code ${error.status}, body was: `,
        error.error
      );
    }
    return throwError(
      () => new Error('Something bad happened; please try again later.')
    );
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
